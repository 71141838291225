import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "blåa" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "blåa-skosnören"
    }}>{`Blåa Skosnören`}</h1>
    <p>{`Vill du piffa upp dina skor med ett bar blåa skosnören så har du kommit helt rätt! Blåa skosnören passar till allt från blåa sneakers där du vill ha en enhetlig färg, till ett par ljusa, vita, tygskor där du vill få lite kontrast och få skorna att sticka ut lite mer.`}</p>
    <p>{`Färgen blått är lite utav en paraplyterm för en mängd olika nyanser och färger. Allt från väldigt mörka och djupa toner till de lite mer ljusare, nästan bleka, färgerna med blåa toner. Ibland stöter man på färgnamn som "navy", "marinblå" eller "marin" och vi har valt att samla alla dessa under samma sida.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      